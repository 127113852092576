import React, { Component }  from 'react';
import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutDiv from '../components/section/about_div';
import AboutUs from '../components/section/about_us';
import Owlslider from '../components/section/owlslider';
import SubHeader from '../components/layout/subheader';
import config from '../constant';
import AboutDurgesh from '../components/section/about_durgesh';
import ClientFeedback from '../components/section/client_feedback';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import { Container } from 'react-bootstrap';

class OneonOne extends Component {
    componentDidMount(){
        document.title = "One-on-One Personal Training | "+ config.SITE_NAME;
      }
        render(){
    return(
        <div>
            <Header/>
            <SubHeader  Title='One-on-One Personal Training' subtitle=' ' BkImg={config.ImageUrl+'One-on-One-Personal-Traning-banner-.jpg'} /> 


            
    
    <Container className='AboutSec py-5'>
     
        <Row className='py-3'>
            <Col   lg="6">
            <div className='aboutconDiv'>

            <h5> "The real transformation does not just involve weight loss for a period of time, but transforms your body, mind, and lifestyle."</h5>
<p>
Are you getting the right form when you work out? Tired of paying yearly gym memberships and not gaining any results? Do you have fear of lifting weights? 
</p>

<p>Don't waste any time and effort. Your dedicated training professional who has a history of meeting fitness goals can proactively attend to your specific condition and guide you throughout your fitness journey. </p>
</div>
            </Col>
            <Col  lg={{span:5, offset:1}}>

        


            <div className="video-container">

<video  controls>
  <source src={config.ImageUrl+"One-On-One Cover Image.mp4"} type="video/mp4"/>
Your browser does not support the video tag.
</video>
</div>
            {/* <Image src={config.ImageUrl+"One-on-One-image1.jpg"} fluid alt={config.SITE_NAME}></Image> */}

            </Col>
            </Row>
            </Container>


             <Container className='reasonsSec py-sm-4 py-xs-0'>
        
             <Row>
            <Col lg="12">
            <h1 className=" pb-3  mt-0  text-center">benefits of hiring a Personal Trainer</h1>
            

            </Col>
            
        </Row>
        <Row className='py-lg-3 py-xs-0'>
            <Col lg="6">
                 
                     <div className='oneDiv'>
                     <h4>Education</h4>
                    <p>You miss the opportunity to learn about the workouts when you just do it yourself or by watching youtube videos. Obtaining the right information from your PT can make you more educated while choosing the workouts.</p>
                    </div>
             
            </Col>
            <Col lg="6">
            <div className='oneDiv'>
                     <h4>Right Form</h4>
                    <p>You need a right form of posture to get the maximum benefit from your workouts. A PT can help you correct your form and get the most out of a session.</p>
                    </div>
                    </Col>
        </Row>
        <Row className='py-lg-3 py-xs-0'>
            <Col lg="6">
                 
                     <div className='oneDiv'>
                     <h4>Customizable workouts</h4>
                    <p>Whether it is decreasing fat and increasing lean muscle mass, improving strength and cardiovascular performance, or managing injury prevention and joint pain, the customized programs planned by our PT can help address the issues. Bring you confidence by driving the fear out of you.
</p>
                    </div>
             
            </Col>
            <Col lg="6">
            <div className='oneDiv'>
                     <h4>Set goals effectively</h4>
                    <p>Effective goal setting which is realistic and achievable can bring progress. A PT can precisely plan and set goals, so you avoid excess & ineffective work.
</p>
                    </div>
                    </Col>
        </Row>

        
         
    </Container>
             <Footer/>


        </div>
    );

}
    }
export default OneonOne;