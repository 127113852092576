import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import config from '../../constant';
import { NavLink } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Ratio from 'react-bootstrap/Ratio';

class ClientFeedback extends Component {


        render() {

           
    return(
        <>
     

    <Container className=' py-5'>
      
       
        <Row className='feedbackSection  py-3'>
            <Col lg="12">

                <div className='feedbackDiv'> 
                 
                   <div className='feedbackImage'><Image  src={config.ImageUrl+"comma.png"} fluid alt={ config.SITE_NAME} /></div> 
                    <div className='text2'>
                    <p>"Rome was not built in a day" very truly said and yes I can use
this phrase for my Fitness Journey.....<br></br>
I started my Fitness Journey under the guidance and training
of Durgesh Jha Sir. He is a great coach with a wide knowledge in
fitness world. He is very hardworking, friendly, polite and
listened to all my problems and tries to resolve the health issues
with customised workouts. He not only helped me in reducing
my weight but he completely cured my Sinus and Vertigo
problems. He has always pushed me and motivated me to
achieve my goal. I was under his personal training for 3 months
and lost almost 16kgs and reduced inches. I started losing body
fat percentage and he planned such a workout and diet which
helped me to tone my body. I would personally recommend
everyone to start their Fitness Journey under Durgesh Sir
guidance. Thanks a ton Durgesh Sir for all your support and
guidance you gave throughout my journey.</p>

<h5>Alefiya. S</h5>
<h6>Home maker</h6>

                    </div>
                    </div>
            </Col>




            <Col lg="12">

<div className='feedbackDiv'> 
 
   <div className='feedbackImage'><Image  src={config.ImageUrl+"comma.png"} fluid alt={ config.SITE_NAME} /></div> 
    <div className='text2'>
    <p>Thank you Durgesh Jha Sir for your love, determination, and
sportsmanship. We're proud to call you our coach! Thank you for
your intense energy, support, challenging workouts, and being a
remarkable trainer! Thank you for choosing me to be a part of
the team and for believing in me.<br></br>
I don't think I would've been able to reach here today if it wasn't
you motivating me in every step of my journey.
I have been depressed many times but you never let me gave up
on myself.<br></br>
As you used to say, make fitness your lifestyle in the long term.
I won't quit and won't stop trying until I reach my goal.
Thank you for motivating me.<br></br>
Couldn't ask for a better trainer❤️.<br></br>
<b>Results 46.5 kgs to 51.8kgs (till date)</b>
</p>

<h5>Misbah Khan</h5>
<h6>student</h6>

    </div>
    </div>
</Col>


 


     <Col lg="12">

<div className='feedbackDiv'> 
 
   <div className='feedbackImage'><Image  src={config.ImageUrl+"comma.png"} fluid alt={ config.SITE_NAME} /></div> 
    <div className='text2'>
    <p>Best Trainer I’ve ever had.Patient n friendly.Finally I see the
result n my body feels great.More confident,stronger n
healthier.Thank you so much to Durgesh sir for being so
dedicated and sincere in your job.I definitely recommend
Durgesh Sir to anyone looking for great personal trainer <br></br>

– He is extremely reliable & punctual – always set up & ready to
start training ahead of time, with an exercise routine planned.<br></br>
I thoroughly enjoy working out with Durgesh<br></br>
I would recommend Durgesh for every individual who is looking
forward to achieve great fitness level and improve your overall
well being. I came across so many trainers but nobody can beat
the level of personal attention he gives you while training. I aim
to continue to train and maintain a good level of fitness with his
help for a long time to come.<br></br>
So, let’s prioritise our health and give it the time and care it
needs before it’s too late. Everything else can wait!
Better late than never, right?<br></br>
Cheers everyone and have a happy, healthy and fit life 😇
</p>

<h5>Erica hlawndo</h5>
<h6></h6>

    </div>
    </div>
</Col>    






<Col lg="12">

<div className='feedbackDiv'> 
 
   <div className='feedbackImage'><Image  src={config.ImageUrl+"comma.png"} fluid alt={ config.SITE_NAME} /></div> 
    <div className='text2'>
    <p>Mr Durgesh is an excellent fitness trainer. His effort for making
me fit. His knowledge about muscle actions and anatomy helps
in proper training. In addition to fitness he also knows and share
nutrition diet plan for having good health. I appreciate for his
dedication.
</p>

<h5>Dr. Subhash</h5>
<h6>Professor</h6>

    </div>
    </div>
</Col> 





<Col lg="12">

<div className='feedbackDiv'> 
 
   <div className='feedbackImage'><Image  src={config.ImageUrl+"comma.png"} fluid alt={ config.SITE_NAME} /></div> 
    <div className='text2'>
    <p>I am Anjali Shelke and have been training with Durgesh for the
past 2 years and since then I never looked back.<br></br>
I know that I would never have achieved the level of fitness and
wellness I have at my age, (currently 30) if not for the personal
attention given in my training sessions. Durgesh is always very
encouraging and has a vast knowledge of his profession.<br></br>
Durgesh is focused on helping me achieve my goals and makes
every session challenging (& somehow fun). My fitness has
increased noticeably and I’ve seen improvements in my ability to
complete more sit ups / core exercises, lift progressively heavier
weights & generally push myself harder throughout each
session. I actually look forward to my workouts.<br></br>
<b>Importantly:</b><br></br>
– He ensures that my technique is always correct to avoid any
injuries
</p>

<h5>Anjali Shelke</h5>
<h6> </h6>

    </div>
    </div>
</Col> 


<Col lg="12">

<div className='feedbackDiv'> 
 
   <div className='feedbackImage'><Image  src={config.ImageUrl+"comma.png"} fluid alt={ config.SITE_NAME} /></div> 
    <div className='text2'>
    <p>I am Bhupeshkumar from Rajasthan. Staying in pune from past 4
years for my Architecture studies.<br></br>
First of all I wanted to thank Mr.Durgesh Jha sir for not only
transforming my body into the best shape but also Supporting
me during my tough times.<br></br>
One of the most professional, best in the field trainer. Definitely
I would suggest him if you are looking for weight loss/muscle
gain or any kind of programme related to fitness.<br></br>
(results - 2 Months ago - 57KG weak and no strength) Now - 61.1
KG (Feeling confident, healthy and the overall strength has
increased)
</p>

<h5>Bhupesh Kumar</h5>
<h6> Student, Founder -
07sketches
 </h6>

    </div>
    </div>
</Col> 




<Col lg="12">

<div className='feedbackDiv'> 
 
   <div className='feedbackImage'><Image  src={config.ImageUrl+"Salman_s Pic.jpg"} fluid alt={ config.SITE_NAME} /></div> 
    <div className='text2'>
    <p>To the best gym trainer I have ever had. Durgesh sir, thank u so
much for being so dedicated and sincere towards your job.<br></br>
I feel deeply indebted to you. I have tried so many gyms but
nothing suited me as much as u did. You have really helped me in
getting back my toned body.<br></br>
With your help now I feel like I am in love with workout and
sweating. It has become my new hobby to keep working nd
never ever make an excuse to not to be at the gym.
Thanks for bringing this magical effect on me.<br></br>
I would love to endorse your work as you are completely
trustworthy and efficient. I'm so thankful to u for being my
trainer, mentor, and my best friend.<br></br>
My journey from obesity to health would have never been
possible without you.
</p>

<h5>Salman F Khan</h5>
<h6>   </h6>

    </div>
    </div>
</Col> 





<Col lg="12">

<div className='feedbackDiv'> 
 
   <div className='feedbackImage'><Image  src={config.ImageUrl+"Leena_s transformation Pic.jpg"} fluid alt={ config.SITE_NAME} /></div> 
    <div className='text2'>
    <p>I would love to take this opportunity to express my appreciation
and gratitude to Durgesh for his excellent work in helping me achieve my target of reducing my
weight, which was nothing less than a wish-fulfillment towards my wellness and confidence-building.<br></br>
I was not too confident when I walked in the 1 st time, however,
after talking to him, I was able to gather that Durgesh has good knowledge about his job which later was
manifested through his expertise in customized training by combining the diet with workout and no
additional supplement.<br></br>
Whoa! I lost 20Kgs in 3 months quite unbelievable as I had been to different places but did not get this kind of a result. Now I not only feel more confident but also feel elated when people compliment me about my appearance.
</p>

<h5>Leena</h5>
<h6>Entrepreneur   </h6>

    </div>
    </div>
</Col> 
       
        </Row>

        
         
    </Container>


     

     
        </>

    );
}
    }
export default ClientFeedback;