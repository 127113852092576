import React, { useState, useEffect } from "react";
import { ButtonGroup , Button, Container, Col, Row} from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';
 import config from "../../constant";
 import {Fade} from 'react-awesome-reveal'
import { NavLink } from "react-router-dom";

 const SliderObj = [
    {
        imgUrl: config.ImageUrl +'Banner3.jpg',
        imgAlt: config.SITE_NAME,
        title: 'Make Your',
        title2: 'Body Shape',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',  
    },
    // {
    //   imgUrl: config.ImageUrl +'Banner-2.jpg',
    //   imgAlt: config.SITE_NAME,
    //   title: 'Integrated Security Solution ',
    //   title2: 'with Better Control',
    //   desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    // },
     
     
];

  

function validate(evt) {
  var theEvent = evt || window.event;
  var key = theEvent.keyCode || theEvent.which;
  key = String.fromCharCode( key );
  var regex = /[0-9]|\./;
  if( !regex.test(key) ) {
    theEvent.returnValue = false;
    if(theEvent.preventDefault) theEvent.preventDefault();
  }
}
//============================================
    
 
const Banner = () => {

  
//console.log("PROPS in MODAL", props);

//  const [showLogin, setShowLogin] = useState(false);

   
    return(
      <>
     <div style={{ 
      backgroundImage: `url( ${config.ImageUrl +'Banner3.jpg'} )` 
    }}  className="BannerDiv">
{/* 
           <Container> <div className="bannerText ">
                <h2>Make your <br></br>body shape</h2>
            </div></Container> */}
        
     </div>

     <Container>
            <Row>
                <Col sm={{span:5, offset:1 }}>
                  <NavLink to="about"><div className="yellow"> ABOUT<br></br>'My Fitness Journey'</div></NavLink>  
                </Col>
                <Col sm="5">
                <NavLink to="about_founder"> <div className="brown">About Founder	</div></NavLink>

                </Col>
            </Row>
        </Container>
    </>
        );
}
   
export default Banner;