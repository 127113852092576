import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';
import {Fade} from 'react-awesome-reveal';

 class NetworkSolutions extends Component {

    componentDidMount(){
        document.title = "Network Security Solutions | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='Network Security Solutions' subtitle='' BkImg={config.ImageUrl + "Advantages-of-Network-Security-Banner.jpg"} /> 

<Container className=" py-5 ProductsSection ">
     <Row >
        <Col lg="12">
        <p>
        Network security or cybersecurity is one of the most critical aspects of any business today. Statistics show that around 236.1 million ransomware attacks occurred globally in the first half of 2022. Hence it is essential to set up a secured network to ensure there is absolute security. Most importantly, our team not only works on the security architecture but also works on training the teams. It is also said that there are two types of companies, either they are attacked or they will be attacked.


</p> 

<p>Network security is important as it is about a company’s sensitive data, intellectual property, etc. Companies have to face huge losses to recover their data from hackers.
</p>
 
   
                              </Col>
       

    </Row>

  
</Container>


 <section className='advantageSection pb-5'>
    <Container>
        <div className='text-center'>
        <h1 className="  py-5  mt-0">Advantages of Network Security</h1>

        </div>
        <Row>
            <Col lg="6">
                <div className='advantageDiv'>
                    <h4> Protects Confidential Data</h4>
                    <p>Network security protocols like encryption, authentication, and access control ensure that only authorized users can have the access to the data.</p>
                </div>
            </Col>
            <Col lg="6">
            <div className='advantageDiv'>
                    <h4> Prevents Data Theft</h4>
                    <p>Security protects data from being stolen or modified. Data security being an important aspect for any organization, firewalls, antivirus software, and intrusion detection systems are some important elements that should be in place.</p>
                </div>
            </Col>
        </Row>
        <Row>
            <Col lg="6">
            <div className='advantageDiv'>
                    <h4> Safeguards Network Infrastructure</h4>
                    <p>Network security helps to protect the network infrastructure, such as routers, switches, and servers, from being attacked or compromised. 

 </p>
                </div>
            </Col>
            <Col lg="6">
            <div className='advantageDiv'>
                    <h4> Enhances Compliance</h4>
                    <p>Network security measures help organizations comply with data privacy regulations, government regulations, and industry standards. </p>
                </div>
            </Col>
        </Row>
    </Container>
    </section>  


     
        <Footer/>
        </div>
    );
} 
}
export default NetworkSolutions;