import React, { Component }  from 'react';
 import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image'
import { NavLink } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
 import Col from 'react-bootstrap/Col';
import config from '../../constant';

 

       class Header extends Component {
    
    render(){
    return(

      <div>
 <div className='topBar  d-none '>
        <Container fluid>
          <Row>
            <Col lg="12" className='text-center'>
              <Navbar>
            <Nav className='mx-auto justify-content-center'>
            <li> <a  href="https://www.facebook.com/CAMline-Solutions-India-Pvt-Ltd-100223158445111/"   activeclassname="active" target='_blank'><i className='fa fa-facebook'></i></a></li> 
        <li>  <a href="https://www.linkedin.com/company/camline-security-solutions/"   activeclassname="active" target='_blank'><i className='fa fa-linkedin'></i></a></li>
          </Nav>
          </Navbar>
            </Col>
          </Row>
        </Container>
      </div>      
        <Navbar collapseOnSelect expand="lg" className="justify-content-center" >
      <Container>
        <Navbar.Brand> <NavLink to="/"   activeclassname="active"><Image src={config.ImageUrl+"Brand-logo.png"}  fluid/> </NavLink> </Navbar.Brand>
        
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className='pull-right'/>
          {/* <Nav className="mx-auto justify-content-center py-3 d-none d-sm-block"  >
          <span>Certificate : ISO 9001 : 2015 | ISO 14001 : 2015 | MSME | SSI | NSIC  </span>

            
          </Nav> */}
          
          <Navbar.Collapse id="responsive-navbar-nav">

          <Nav className='mx-auto'>
          <NavLink to="/"   activeclassname="active">Home</NavLink>
          <NavDropdown title="Services" id="basic-nav-dropdown"  renderMenuOnMount={true} align="end">

<NavDropdown.Item href="" > 
<NavLink to="/one-on-on-personal-training"   activeclassname="sactive">One-on-One Personal Training</NavLink></NavDropdown.Item>

<NavDropdown.Item href="" > <NavLink to="/group-training"   activeclassname="sactive">Group Training</NavLink></NavDropdown.Item>


<NavDropdown.Item href="" > <NavLink to="/nutritional_consultancy"   activeclassname="sactive">Nutrition Consultation</NavLink></NavDropdown.Item>

<NavDropdown.Item href="" > <NavLink to="/online_training"   activeclassname="sactive">Online Training</NavLink></NavDropdown.Item>
 
</NavDropdown> 
          <NavLink to="/client_feedback"   activeclassname="active">Client Stories</NavLink>
          <NavLink to="/media"   activeclassname="active">Media</NavLink>
          <NavLink to="/contact"   activeclassname="active">Contact</NavLink>

              {/* <NavLink to="/service"   activeclassname="active">Services</NavLink> */}
            {/* <Nav.Link href="#deets">More deets</Nav.Link>
            <Nav.Link eventKey={2} href="#memes">
              Dank memes
            </Nav.Link> */}
          </Nav>
          </Navbar.Collapse>

          
         

       </Container>
    </Navbar>
    </div>
           );
}

  }
export default Header;
