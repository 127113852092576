import React, { Component }  from 'react';
import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutDiv from '../components/section/about_div';
import AboutUs from '../components/section/about_us';
import Owlslider from '../components/section/owlslider';
import SubHeader from '../components/layout/subheader';
import config from '../constant';


class About extends Component {
    componentDidMount(){
        document.title = "About Us | "+ config.SITE_NAME;
      }
        render(){
    return(
        <div>
            <Header/>
            <SubHeader  Title='About Us' subtitle='My Fitness Journey' BkImg={config.ImageUrl+'trainer_banner.jpg'} /> 

            <AboutDiv/>
            <Footer/>


        </div>
    );

}
    }
export default About;