import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import config from '../../constant';
import { NavLink } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Ratio from 'react-bootstrap/Ratio';

class AboutDiv extends Component {
        render() {

    return(
        <>
    <Container className='AboutSec py-3'  >

        
        <Row className='pt-5'>

       

            <Col lg="6"  >
                <div className='aboutconDiv'>
                <h5> <i>"A customized weight training and diet plan, along with focus and consistency can radically change the aesthetics of even low participant groups like children, women, and population above 50 years of age, who do not focus much on weight training."</i> </h5>
                
                <p>Having suffered from health issues like anxiety sleeping issues, hypertension, and alcohol addiction, impacted my career. The most important thing I realized for overcoming these issues is to build a healthy body first, which reflects on my mental condition and sharp focus on my goals. </p>

                <p>My first step started with eating healthy meals and exercising my body. My ability to self-motivate and detailed analysis to transform my body and mind made me question, why can't I transform others? This paved a way for the fitness industry as a personal trainer. In a span of 3 to 4 years, my nutrition expertise and personalized strength training plans could transform multiple categories of people with different ailments. </p>
                <p>'MyFitness Journey' is an exceptional thought to not just transform people but educate them on 'fitness, not just a goal but a lifestyle'. A team of experts who diligently work on weight training techniques, nutrition plans, and health education are part of achieving this mission.</p>
            <p><i>- Durgesh Jha</i></p>
                </div>
            </Col>

            <Col lg={{span:5, offset:1}}>
             <div className="adBoxImage"  style={{  backgroundImage: `url( ${config.ImageUrl+"about_side.jpg"} )`, }}  ></div>
                {/* <Image src={config.ImageUrl+"about_side.jpg"} fluid alt={config.SITE_NAME}></Image> */}
            </Col>
           
        </Row>

        <Row className="py-lg-5 pt-3">
            <Col>
           
           
            </Col>
        </Row>
    </Container>

    


    {/* <Container className='reasonsSec py-5'>
        <Row>
            <Col lg="12">
            <h1 className=" pb-3  mt-0  text-center">Mission & Vision</h1>
            

            </Col>
            
        </Row>

        <Row className='py-3'>
            <Col lg="6">
                 
                    <Image  src={config.ImageUrl+"mission.png"} fluid alt={ config.SITE_NAME} />
                    <div className='text'>
                     <h4>Mission</h4>
                    <p>Building long term relationship with our customers by creating real values for them through our innovative products and services.</p>
                    </div>
             
            </Col>
            <Col lg="6">
            <Image  src={config.ImageUrl+"vision.png"} fluid alt={ config.SITE_NAME} />
                    <div className='text'>
                     <h4>Vision</h4>
                    <p>Our company vision is to offer exceptional, tailored security solutions at an affordable cost, supported around the clock.</p>
                    </div>
                    </Col>
        </Row>

        
         
    </Container> */}


     

     
        </>

    );
}
    }
export default AboutDiv;