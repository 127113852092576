import React, { useState, useEffect } from "react";
import { ButtonGroup , Button} from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';
 import config from "../../constant";
 import {Fade} from 'react-awesome-reveal'

 const SliderObj = [
    {
        imgUrl: config.ImageUrl +'Banner2.jpg',
        imgAlt: config.SITE_NAME,
        title: 'Make Your',
        title2: 'Body Shape',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',  
    },
    // {
    //   imgUrl: config.ImageUrl +'Banner-2.jpg',
    //   imgAlt: config.SITE_NAME,
    //   title: 'Integrated Security Solution ',
    //   title2: 'with Better Control',
    //   desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    // },
     
     
];

  

function validate(evt) {
  var theEvent = evt || window.event;
  var key = theEvent.keyCode || theEvent.which;
  key = String.fromCharCode( key );
  var regex = /[0-9]|\./;
  if( !regex.test(key) ) {
    theEvent.returnValue = false;
    if(theEvent.preventDefault) theEvent.preventDefault();
  }
}
//============================================
    
 
const Slider = () => {

  
//console.log("PROPS in MODAL", props);

//  const [showLogin, setShowLogin] = useState(false);

   
    return(
      <>
    <Carousel fade interval={1000}>
       {
    SliderObj.map( (val , i) => (     
      <Carousel.Item key={i}>
        <img
          className="d-block w-100"
          src={`${val.imgUrl}`}
          alt={`${val.imgAlt}`}
        />



        <Carousel.Caption  >
            
        <Fade direction='left'  delay="40" triggerOnce="true">

          
          <h3>{`${val.title}`} <br></br> {`${val.title2}`}  </h3>


        
      <Button className="btn btn-success" >Our Project</Button>
      <Button className="btn btn-default">Learn More</Button>
      </Fade>

        
            </Carousel.Caption>
      </Carousel.Item>
)
)
}  
 
    </Carousel>
    </>
        );
}
   
export default Slider;