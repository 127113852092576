import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import config from '../../constant';
import { NavLink } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Ratio from 'react-bootstrap/Ratio';
import Owlslider from './owlslider';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {Fade} from 'react-awesome-reveal'
import { Button } from 'react-bootstrap';

export const images = [
    {
        imgUrl: config.ImageUrl+'1-One-on-One1.jpg',
        imgCaption:'One-on-One Personal Training',
        url:'one-on-on-personal-training'
    },
    {
        imgUrl: config.ImageUrl+'2-Group-Training.jpg',
        imgCaption:'Group Training',
        url:'group-training'
    },
    {
        imgUrl: config.ImageUrl+'3-Nutritional-Consultant.jpg',
        imgCaption:'Nutrition Consultation',
        url:'nutritional_consultancy'
    },
    {
        imgUrl: config.ImageUrl+'4-Online-Training.jpg',
        imgCaption:'Online Training',
        url:'online_training'
    },
    
]



const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: true,
    autoPlaySpeed: 3000,
    autoPlayTimeout: 3000,
    autoplayHoverPause: true,
    loop:true,

    // navText: ['<img src="assets/images/hotel/Left-Arrow.png">', '<img src="assets/images/hotel/Right-Arrow.png">'],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 1,
        },
        1000: {
            items: 1,

        }
    },
};
class AboutServices extends Component {
        render() {

    return(
        <>
     
     <section className='serviceSec '>

    <Container className='py-5'>
        <Row>

        <h1 className=" pb-5  text-center">Our Training & Consultation		</h1>

      

        {/* <OwlCarousel className='owl-theme'  {...options}> */}
        
        {images.map( (val , i) => (  
          
    <Col className='' lg="3" sm="6" key={i}>
    {/* <Image src={val.imgUrl} alt={`${val.imgCaption}`} fluid />    */}
    <Fade direction='up'  delay={i  *  40 } triggerOnce="true">

    <div className='serviceDiv'>
            <div className="productDiv"  >	
            <div className='imgDiv' style={{ 
      backgroundImage: `url( ${val.imgUrl} )`  }}></div>	       
				         </div>
                         <div className="captionDiv">
						    <h3>{`${val.imgCaption}`} </h3>

                            <NavLink to={`${val.url}`} > Learn More</NavLink> 

 					     </div>
                          </div>
                          </Fade>

                         </Col>
                         )  )}

{/* </OwlCarousel> */}
             
        </Row>
    </Container>


     </section>


     

    <section className='testimonials py-5 py-xs-5'>
    <Container>
            <Row >

                <Col lg="5" className='text-left'>
                <Fade direction='left'  delay="40" triggerOnce="true">
 
 <h2>Client's Feedback</h2>
                    <h4>What our clients<br></br> say about us</h4>
 </Fade>
                </Col>
                <Col lg="7" className='text-left'>
                    <div className='px-5'>

                              <OwlCarousel className='owl-theme'  {...options}>
                              <div className='item'  >

                    <Fade direction='right'  delay="60" triggerOnce="true">
                    <div className='testiMonialsBox'>
                        <div className='testiMonialsImg'>
                            <Image src={config.ImageUrl + "comma.png"} />
                        </div>



                        <div className='testiMonialsText'>
                            <h3>Leena</h3>
                        </div>
                    </div>

                    {/* <h5>"He ensures that my technique is always correct to avoid any injuries..." </h5> */}
                    <h5>"Whoa! I lost 20Kgs in 3 months quite unbelievable as I had been to different places but did not get this kind of a result.." </h5>
                     
                    </Fade>
                    </div>

                    <div className='item'  >

                    <Fade direction='right'  delay="60" triggerOnce="true">
                    <div className='testiMonialsBox'>
                        <div className='testiMonialsImg'>
                            <Image src={config.ImageUrl + "comma.png"} />
                        </div>



                        <div className='testiMonialsText'>
                            <h3>ANJALI SHELKE</h3>
                        </div>
                    </div>

                    <h5>"He ensures that my technique is always correct to avoid any injuries..." </h5>
                     
                     
                    </Fade>
                    </div>
                    </OwlCarousel>
                    </div>
                </Col>
                </Row>
                </Container>
    </section>



    <Container className='reasonsSec py-5 '>
        <Row>
            <Col lg="12">
            <h1 className=" pb-3   mt-0  text-center">Training Categories</h1>

            </Col>
            
        </Row>

        <Row className='py-lg-3'>
            <Col lg="4" sm="6">
                 
            <Fade direction='left'  delay="40" triggerOnce="true">
        <div className='ImageDiv'>
    <div className='ImageBox'>
    <Image  src={config.ImageUrl+"1-icon.png"} fluid alt={ config.SITE_NAME} />
    </div>
                    <div className='text'>
                    <p>50 years & above for general wellbeing</p>
                    </div>
                    </div>
                    </Fade>
             
            </Col>
            <Col lg="4" sm="6">
            <Fade direction='right'  delay="80" triggerOnce="true">
            <div className='ImageDiv'>
    <div className='ImageBox'>
    <Image  src={config.ImageUrl+"2-icon.png"} fluid alt={ config.SITE_NAME} />
    </div>
                    <div className='text'>
                       <p> <span className='yellowText'>Special Category</span>
                    
                        <li>Post Delivery</li>
                        <li>Arthritis</li>
                        <li>Sciatica</li>
                        <li>Spondylitis</li>
                        <li>Split Disc</li>
                        <li>Osteoporosis</li>
                        </p>
                    </div>
                    </div>
           
                    </Fade>
                    </Col>

                    <Col lg="4" sm="6">
            <Fade direction='right'  delay="80" triggerOnce="true">

            <div className='ImageDiv'>
    <div className='ImageBox'>
    <Image  src={config.ImageUrl+"3-icon.png"} fluid alt={ config.SITE_NAME} />
    </div>
                    <div className='text'>
                    <p>Executive women for fat loss</p>
                    </div>
                    </div>
                    </Fade>
                    </Col>
        
            <Col lg="4" sm="6">
            <Fade direction='left'  delay="120" triggerOnce="true">
            <div className='ImageDiv'>
    <div className='ImageBox'>
    <Image  src={config.ImageUrl+"4-icon.png"} fluid alt={ config.SITE_NAME} />
    </div>
                    <div className='text'>
                    <p>Strength training for athletes</p>
                    </div>
                    </div>
                    </Fade>
             
            </Col>
            <Col lg="4" sm="6">
            <Fade direction='right'  delay="160" triggerOnce="true">

            <div className='ImageDiv'>
    <div className='ImageBox'>
    <Image  src={config.ImageUrl+"5-icon.png"} fluid alt={ config.SITE_NAME} />
    </div>
                    <div className='text'>
                    <p>Physically Disabled</p>
                    </div>
                    </div>
                    </Fade>
                    </Col>
                    <Col lg="4" sm="6">
            <Fade direction='right'  delay="160" triggerOnce="true">

            <div className='ImageDiv'>
    <div className='ImageBox'>
    <Image  src={config.ImageUrl+"6-icon.png"} fluid alt={ config.SITE_NAME} />
    </div>
                    <div className='text'>
                    <p>Men for Strength training</p>
                    </div>
                    </div>
                    </Fade>
                    </Col>
        </Row>
    </Container>



 

 
         </>

    );
}
    }
export default AboutServices;