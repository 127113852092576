import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/home';
import ErrorPage from './pages/404';
import About from './pages/about';
import Contact from './pages/contact';
 
import ProductsMain from './pages/productsMain';
 
import ScrollToTop from './ScrollToTop';
 
import SystemIntegration from './pages/system_integration';
import ProjectManagement from './pages/project_management';
import NetworkSolutions from './pages/network_solutions';
import Turnkey from './pages/turnkey';
import ProjectAmc from './pages/project_amc';
import AboutTrainer from './pages/abouttrainer';
import ClientFeed from './pages/client_feedback_main';
import OneonOne from './pages/one-on-one';
import GroupTraning from './pages/group_training';
import Nutrition from './pages/nutrition';
import OnlineTraining from './pages/online_training';
import Media from './pages/media';

function App() {
  return (
   <BrowserRouter>
      <ScrollToTop />

    <Routes>
      <Route path="/" element= {<Home />} />
      <Route path="/home" element= {<Home />} />
      <Route path="/contact" element= {<Contact />} />
      <Route path="/about" element= {<About/>} />
      <Route path="/about_founder" element= {<AboutTrainer/>} />
      <Route path="/client_feedback" element= {<ClientFeed/>} />
      <Route path="/one-on-on-personal-training" element= {<OneonOne/>} />
      <Route path="/group-training" element= {<GroupTraning/>} />
      <Route path="/nutritional_consultancy" element= {<Nutrition/>} />
      <Route path="/online_training" element= {<OnlineTraining/>} />
      <Route path="/media" element= {<Media/>} />

        <Route path="*" element={<ErrorPage />} />

    </Routes>

   </BrowserRouter>
  );
}

export default App;
