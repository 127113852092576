import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';
import {Fade} from 'react-awesome-reveal';

 class ProjectManagement extends Component {

    componentDidMount(){
        document.title = "Consultation Project Management | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='Consultation Project Management' subtitle='' BkImg={config.ImageUrl + "Consultation-Project-Management-Banner.jpg"} /> 

<Container className=" py-5 ProductsSection ">
     <Row >
        <Col lg="12">
        <p>
        Our experience in project execution over these years has made us aware of all the nitty-gritty of security systems and this helps organizations with their security-related issues. Right from risk management to system architecture and installation, we help them execute the right way at every step. Whether it is a commercial or a residential complex, we list down all the possible threats from every checkpoint. Mapping all the ideal requirements helps the organization arrange for the right budgets and infrastructure too.</p> 
        <p>     
The team helps you design a strategy that is customized per the specific environment. Our experienced project managers will help you plan, coordinate, and execute each project with precision and accuracy. From project scoping and planning to resource allocation and reporting, we’ll ensure that your security projects are delivered on time.


</p> 
 
   
                              </Col>
       

    </Row>

  
</Container>


     
        <Footer/>
        </div>
    );
} 
}
export default ProjectManagement;