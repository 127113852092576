import React, { Component }  from 'react';
import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutDiv from '../components/section/about_div';
import AboutUs from '../components/section/about_us';
import Owlslider from '../components/section/owlslider';
import SubHeader from '../components/layout/subheader';
import config from '../constant';
import AboutDurgesh from '../components/section/about_durgesh';
import ClientFeedback from '../components/section/client_feedback';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import { Container } from 'react-bootstrap';

class GroupTraning extends Component {
    componentDidMount(){
        document.title = "Group Training | "+ config.SITE_NAME;
      }
        render(){
    return(
        <div>
            <Header/>
            <SubHeader  Title='Group Training' subtitle=' ' BkImg={config.ImageUrl+'Group-Training-Banner.jpg'} /> 


            
    
    <Container className='AboutSec py-5'>
      

        <Row className='py-3'>
            <Col   lg="6">
            <div className='aboutconDiv'>

            <h5> "Although One-on-One training helps you achieve individual fitness goals, group training can bring a great amount of energy. The energy is a great motivation for encouraging you to improve social health, create set schedules and accountability, and build confidence and support. Being surrounded by people of different abilities can help balance your pace of the workout. Some of our group workouts include Group Weight Training, Running, and High-Intensity Interval Training (HIIT)"</h5>
           </div>
            </Col>
            <Col  lg={{span:5, offset:1}}>
            {/* <Image src={config.ImageUrl+"Online-Training-image.jpg"} fluid alt={config.SITE_NAME}></Image> */}
            <div className="video-container">

<video  controls>
  <source src={config.ImageUrl+"run with me.mp4"} type="video/mp4"/>
Your browser does not support the video tag.
</video>
</div>

            </Col>
            </Row>
         
             <Row className=' pt-5'>
            <Col lg="12">
            <h1 className=" pb-3  mt-0  text-left">Benefits of HIIT</h1>
            

            </Col>
            
        </Row>
        <Row>
                <Col lg="12">
                
<p>

HIIT is a group of micro-workouts that are as shorter as 20 minutes and increase endurance by allowing you to push yourself to your limits and recover. This gradually allows you to perform at a higher level for a longer period of time</p>

<p>HIIT workouts are versatile with many forms of exercise switched between running, jumping, cycling, and resistance training. Increases your metabolic rate for hours after the workout is completed. </p>

<p> HIIT workouts can help build lean muscle mass and increase overall strength. The function and structure of the muscle can be improved by combining resistance training with high-intensity cardio.</p>
                </Col>
            </Row>
            </Container>


              
             <Footer/>


        </div>
    );

}
    }
export default GroupTraning;