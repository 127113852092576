import React, { Component }  from 'react';
import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutDiv from '../components/section/about_div';
import AboutUs from '../components/section/about_us';
import Owlslider from '../components/section/owlslider';
import SubHeader from '../components/layout/subheader';
import config from '../constant';
import AboutDurgesh from '../components/section/about_durgesh';
import ClientFeedback from '../components/section/client_feedback';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import { Container } from 'react-bootstrap';

class OnlineTraining extends Component {
    componentDidMount(){
        document.title = "Online Training | "+ config.SITE_NAME;
      }
        render(){
    return(
        <div>
            <Header/>
            <SubHeader  Title='Online Training' subtitle=' ' BkImg={config.ImageUrl+'Online-Training-Banner.jpg'} /> 


            
    
    <Container className='AboutSec onlineTraining py-5'>
      

        <Row className='py-3'>
            <Col   lg="6">
            <div className='aboutconDiv'>

            <h5> "You love traveling! A parent busy with kids! Distance puts you off! We harmonize all your preferences & avoidances that can lead you toward your fitness journey."</h5>
                           
<p>

Don’t think that it takes a lot to take online personal training – it really doesn’t. All you need is a shortlist of equipment, and you can get started almost immediately after deciding to sign up from any part of the world.  </p>
<p>  Your professional trainer designs and trains your workout in live tailored to your needs, so that you are working out effectively and safely. They help you stay motivated and accountable throughout the season. </p>  

<p> Once you have your equipment all you have to do is set up your computer and an area to work out and you’re ready to go! And we're with you wherever, whenever!</p>  
</div>     
            </Col>
            <Col  lg={{span:5, offset:1}}>
            <div className="adBoxImage"  style={{  backgroundImage: `url( ${config.ImageUrl+"Online-Training-image.jpg"} )`, }}  ></div>
            {/* <Image src={config.ImageUrl+"Online-Training-image.jpg"} fluid alt={config.SITE_NAME}></Image> */}
          
              
    </Col>
            </Row>
          



            </Container>


              
             <Footer/>


        </div>
    );

}
    }
export default OnlineTraining;