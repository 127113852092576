import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import config from '../../constant';
import { NavLink } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Ratio from 'react-bootstrap/Ratio';

class AboutDurgesh extends Component {
        render() {

    return(
        <>
    

    
    <Container className='AboutSec py-5'>
        <Row>
            <Col lg="12">
             

            </Col>
            
        </Row>

        <Row className='py-3'>
            <Col   lg="6">
            <div className='aboutconDiv'>

            <h5> <i>"Certified REPS III Personal Trainer, Nutrition Expert &
Fitness Coach offering 8 years of experience guiding diverse clients to achieve health and wellness goals."</i> </h5>
<p>
Results-oriented and a strong background in designing training and nutrition plans to maximize performance, improve endurance, and increase overall well-being. Smoothly handles questions and resolves concerns with great problem-solving abilities and attention to detail. Has thorough knowledge of diverse strength training programs and human
anatomy & physiology.
</p>
</div>
            </Col>
            <Col  lg={{span:5, offset:1}}>
            {/* <Image src={config.ImageUrl+"durgesh.jpg"} fluid alt={config.SITE_NAME}></Image> */}
            <div className="video-container">

<video  controls>
  <source src={config.ImageUrl+"Founder Durgesh.mp4"} type="video/mp4"/>
Your browser does not support the video tag.
</video>
</div>
            </Col>
            </Row>
            </Container>

    {/* <Container className='reasonsSec py-5'>
        <Row>
            <Col lg="12">
            <h1 className=" pb-3  mt-0  text-center">Mission & Vision</h1>
            

            </Col>
            
        </Row>

        <Row className='py-3'>
            <Col lg="6">
                 
                    <Image  src={config.ImageUrl+"mission.png"} fluid alt={ config.SITE_NAME} />
                    <div className='text'>
                     <h4>Mission</h4>
                    <p>Building long term relationship with our customers by creating real values for them through our innovative products and services.</p>
                    </div>
             
            </Col>
            <Col lg="6">
            <Image  src={config.ImageUrl+"vision.png"} fluid alt={ config.SITE_NAME} />
                    <div className='text'>
                     <h4>Vision</h4>
                    <p>Our company vision is to offer exceptional, tailored security solutions at an affordable cost, supported around the clock.</p>
                    </div>
                    </Col>
        </Row>

        
         
    </Container> */}


     

     
        </>

    );
}
    }
export default AboutDurgesh;