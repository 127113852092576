import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';
import {Fade} from 'react-awesome-reveal';

 class SystemIntegration extends Component {

    componentDidMount(){
        document.title = "System Integration | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='System Integration' subtitle='' BkImg={config.ImageUrl + "System-Integration-Banner.jpg"} /> 

<Container className=" py-5 ProductsSection ">
     <Row >
        <Col lg="12">
        <p>
        System integration especially in the security space plays a very important role. It creates a platform for all the interactions between various applications or systems in one place, thereby increasing the efficiency and turn-around time for any task. This integration allows ease in managing different components. It adds a layer of security for the organization – security gates, surveillance cameras, public addressing systems, etc. can be operated from a single place.

</p> 
 
   
                              </Col>
       

    </Row>

  
</Container>


 <section className='advantageSection pb-5'>
    <Container>
        <div className='text-center'>
        <h1 className="  py-5  mt-0">Advantages of an Integrated Security System</h1>

        </div>
        <Row>
            <Col lg="6">
                <div className='advantageDiv'>
                    <h4> Management Efficiency</h4>
                    <p>An integrated system becomes a centralized point of operations for the security team. The team can handle the burglar alarm and a surveillance system simultaneously without any hassle. This makes it easy for the business to manage security.</p>
                </div>
            </Col>
            <Col lg="6">
            <div className='advantageDiv'>
                    <h4> Enhanced Safety and Productivity</h4>
                    <p>As all systems lead to one source, the security hub can act in tandem with all immediate needs from fire to theft to quick help. Research shows that any commercial place if protected, makes the workers feel safe, adding to their productivity. </p>
                </div>
            </Col>
        </Row>
        <Row>
            <Col lg="6">
            <div className='advantageDiv'>
                    <h4> Reduced Human Involvement</h4>
                    <p>An integrated system reduces the cost of involving security guards. It helps you keep an eye on all the surroundings efficiently using the required manpower. While business owners can focus on their company’s growth, integrated systems will keep an eye on almost everything from managing employees to handling third parties.
 </p>
                </div>
            </Col>
            <Col lg="6">
            <div className='advantageDiv'>
                    <h4> Reduced Human Involvement</h4>
                    <p>An integrated system reduces the cost of involving security guards. It helps you keep an eye on all the surroundings efficiently using the required manpower. While business owners can focus on their company’s growth, integrated systems will keep an eye on almost everything from managing employees to handling third parties.
 </p>
                </div>
            </Col>
        </Row>
    </Container>
    </section>  


    <Container className=' pb-5 text-center components' >
    <div className='text-center'>
        <h1 className="  py-5  mt-0">Components of System Integration</h1>

        </div>
        <Row>
            <Col lg="3">  <Fade direction='up'  delay="40" triggerOnce="true">
 <Image src={config.ImageUrl+"1-access.png"} fluid alt={config.SITE_NAME}></Image>
                    <p> Access Control System</p></Fade></Col>
            <Col lg="3">  <Fade direction='up'  delay="40" triggerOnce="true">
 <Image src={config.ImageUrl+"2-Surveillance-System.png"} fluid alt={config.SITE_NAME}></Image>
                    <p> Surveillance System</p></Fade></Col>
            <Col lg="3"> 
             <Fade direction='up'  delay="40" triggerOnce="true">
 <Image src={config.ImageUrl+"3-Public-Address-System.png"} fluid alt={config.SITE_NAME}></Image>
                    <p> Public Address System</p>
                    
                    </Fade>
                    </Col>
            <Col lg="3">  <Fade direction='up'  delay="40" triggerOnce="true">
 <Image src={config.ImageUrl+"4-Gating-Solution.png"} fluid alt={config.SITE_NAME}></Image>
                    <p> Gating Solutions</p>
                    </Fade></Col>
        </Row>
        </Container>   
        <Footer/>
        </div>
    );
} 
}
export default SystemIntegration;