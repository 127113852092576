import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import config from '../../constant';
import { NavLink } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Ratio from 'react-bootstrap/Ratio';

class MediaContent extends Component {


        render() {

           
    return(
        <>
     

     <Container className='AboutSec MediaPage py-5'>
        <Row>
            <Col lg="12">
             

            </Col>
            
        </Row>

        <Row className='py-3'>
            <Col   lg="6">
            <div className='aboutconDiv'>

            <h5>Geriatric Population (Senior Citizen)</h5>
<p>
Motivation is a very important aspect of personal training for seniors - especially those for whom fitness has never been a focus. They tend to easily become discouraged due to reduced flexibility, balance, strength, and endurance. Moreover, they always build a wall against the fitness routines with the notion that they are 'too old for this' or 'its too risky'. The specialized form of personal training addresses these conditions like loss of balance, coordination, strength, and endurance, which can improve quality of life and independence.
</p>
</div>

            </Col>
            <Col  lg={{span:5, offset:1}}>
            {/* <Image src={config.ImageUrl+"durgesh.jpg"} fluid alt={config.SITE_NAME}></Image> */}
            <div className="video-container">

            <video  controls>
  <source src={config.ImageUrl+"Geriatric Population.mp4"} type="video/mp4"/>
Your browser does not support the video tag.
</video>
</div>

            </Col>
            </Row>
            <hr></hr>


            <Row className='py-3'>
            <Col   lg="6">
            <div className='aboutconDiv'>

            <h5>Women</h5>
        
<p>
Women are more inclined to fitness programs emphasizing cardio and various diet plans. Although these are essential as a part of a holistic fitness program; strength training/weight lifting is essential to long-term wellness. The benefits of strength training for women include; 1) Increased bone density and muscle mass 2) Helps with weight loss 3) Better quality of sleep 4) Increased energy and better functional strength 5) Improves heart health and lowers stress levels 5) And most of all - Boosts Confidence!
</p>
<p>Gradual increase in the number of sessions, low to high measured weights, and carefully planned program with respect to the body type & condition, fitness level, and diet are the key to strength training in women</p>
</div>
            </Col>
            <Col  lg={{span:5, offset:1}}>
            {/* <Image src={config.ImageUrl+"durgesh.jpg"} fluid alt={config.SITE_NAME}></Image> */}
            <div className="video-container">

<video  controls>

  <source src={config.ImageUrl+"Women.mp4"} type="video/mp4"/>
Your browser does not support the video tag.
</video>
</div>
            </Col>
            </Row>

        <hr></hr>

            <Row className='py-3'>
            <Col   lg="6">
            <div className='aboutconDiv'>

            <h5>Men - Compound movement exercises			</h5>
        
<p>
Compound exercises are exercises that work for multiple muscle groups at the same time. An isolation movement is an exercise that involves only one muscle—or one isolated part of the body; examples are—concentration curls with a dumbbell, thigh extensions, triceps extensions, and wrist curls. Such movements have their places—especially in the field of restorative surgery and in bodybuilding, but they are of almost no use in a training program

</p>
<p>A compound exercise involves more than one muscle that produces strength, power, and muscle gains. They increase testosterone and growth hormone, which build muscle and burn fat.  A compound exercise, apart from developing strength, also leads to great improvements in muscular coordination and balance—a result that does not come from the practice of isolation.</p>
</div>
            </Col>
            <Col  lg={{span:5, offset:1}}>
            {/* <Image src={config.ImageUrl+"durgesh.jpg"} fluid alt={config.SITE_NAME}></Image> */}
            <div className="video-container">

<video  controls>
  <source src={config.ImageUrl+"mencompounding.mp4"} type="video/mp4"/>
Your browser does not support the video tag.
</video>
</div>
            </Col>
            </Row>
            </Container>


     

     
        </>

    );
}
    }
export default MediaContent;