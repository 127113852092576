const config = { 
    BASE_URL : "https://server.developer.bicads.com/",
    // BASE_URL : "http://localhost:3001/",
SITE_NAME : 'MyFitness Journey',
contact1:'+91 90407 62840',
contact2:'9040762840',
email_id:'jhadurgesh1792@gmail.com',
ImageUrl:'assets/images/gym/',
fb:"https://www.facebook.com/profile.php?id=100085600652896",
insta:"https://www.instagram.com/myfitnessjourney_dj_2023/"	

 
     
}
export default config;