import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627
    },
    zoom: 11
  };

const mapStyle = [
   { width:'100%' ,
    height:'450px',
    border:0,
    allowFullScreen:'',
    loading:'lazy',
    referrerPolicy:'no-referrer-when-downgrade'
}
]
class Googlemap extends Component {
    render(){
        return(   
            <>
            <Container fluid className="">
            <Row>
                <Col>
                <div  className="d-none" style={{ height: '10vh', width: '100%' }}>
                <GoogleMapReact 
    bootstrapURLKeys={{ key: "" }}
    defaultCenter={defaultProps.center}
    defaultZoom={defaultProps.zoom}
  >
    <AnyReactComponent
      lat={59.955413}
      lng={30.337844}
      text="My Marker"
    />
  </GoogleMapReact>
  </div>
    


                </Col>
            </Row>
        </Container>  
     
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.2548867920145!2d73.91299807409851!3d18.472110270654955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2eb2a46d28d51%3A0xa82e92edbd30e222!2sMY%20Fitness%20journey!5e0!3m2!1sen!2sin!4v1683099517233!5m2!1sen!2sin" style={{ height: '50vh', width: '100%'  }}  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
</>
        )
    }
}
 
export default Googlemap;