import React, { Component }  from 'react';
import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutDiv from '../components/section/about_div';
import AboutUs from '../components/section/about_us';
import Owlslider from '../components/section/owlslider';
import SubHeader from '../components/layout/subheader';
import config from '../constant';
import AboutDurgesh from '../components/section/about_durgesh';
import ClientFeedback from '../components/section/client_feedback';


class AboutTrainer extends Component {
    componentDidMount(){
        document.title = "About Founder | "+ config.SITE_NAME;
      }
        render(){
    return(
        <div>
            <Header/>
            <SubHeader  Title='About Founder' subtitle='Durgesh Jha' BkImg={config.ImageUrl+'about_banner.jpg'} /> 

            <AboutDurgesh/>
            <Footer/>


        </div>
    );

}
    }
export default AboutTrainer;