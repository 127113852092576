import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import config from '../../constant';
import { NavLink } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Ratio from 'react-bootstrap/Ratio';
import {Fade} from 'react-awesome-reveal';

class AboutHome extends Component {
        render() {

    return(
        <>
    <Container className='AboutSec py-3'  >

        
        <Row className='py-5'>
            <Col lg="8">
            <Fade direction='left'  delay="40" triggerOnce="true">

            <h1 className=" pb-3  mt-0  text-left">About CAMline</h1>

                <p>Modern security and access control systems, video surveillance, and public address systems are all areas in which we are experts in the design, implementation, and support of these systems. These systems are integrated, which is essential for collecting incident information and carrying out actions that lower asset and human risk in emergency situations.</p>

                <p>Over the years we have been deploying modern technology in security and access control systems, video surveillance, and public address systems. We design systems that essentially collects incident information and carries out actions that lower asset and human risk in emergency situations.</p>
                </Fade>
            </Col>
            <Col lg="4">
            <Fade direction='right'  delay="40" triggerOnce="true">

                <Image src={config.ImageUrl+"About-us-Image.png"} fluid alt={config.SITE_NAME}></Image>
                </Fade>
            </Col>
        </Row>
    </Container>


 
        </>

    );
}
    }
export default AboutHome;