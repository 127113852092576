import React, { Component }  from 'react';
import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutDiv from '../components/section/about_div';
import AboutUs from '../components/section/about_us';
import Owlslider from '../components/section/owlslider';
import SubHeader from '../components/layout/subheader';
import config from '../constant';
import AboutDurgesh from '../components/section/about_durgesh';
import ClientFeedback from '../components/section/client_feedback';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import { Container } from 'react-bootstrap';

class Nutrition extends Component {
    componentDidMount(){
        document.title = "NUTRITION CONSULTATION | "+ config.SITE_NAME;
      }
        render(){
    return(
        <div>
            <Header/>
            <SubHeader  Title='NUTRITION CONSULTATION' subtitle=' ' BkImg={config.ImageUrl+'Nutritional-Consultation-Banner.jpg'} /> 


            
    
    <Container className='AboutSec py-5'>
      

        <Row className='py-3'>
            <Col   lg="6">
            <div className='aboutconDiv'>

            <h5> "The Internet has loads of information to research on nutrition / healthy foods/expert videos/diet recipes etc. But does it give the search results considering your current lifestyle and workout threshold limits?"</h5>
                           
<p>

Our nutrition experts can navigate you through the customized diet plans by calculating your caloric needs and specific goals like weight loss or weight gain, and help you with the right combination of macronutrients and micronutrients. </p>

<p>
Nutrition counseling is based on your lifestyle, food preferences, exercise style, and medical issues such as diabetes, high blood pressure, thyroid, PCOD, arthritis, osteoporosis, pregnancy, gastrointestinal, etc.  </p>
</div>
            </Col>
            <Col  lg={{span:5, offset:1}}>
            <div className="adBoxImage"  style={{  backgroundImage: `url( ${config.ImageUrl+"Nutritional-Consultation-image.jpg"} )`, }}  ></div>
            

            </Col>
            </Row>
        
           
        <Row>
                <Col lg="12">


               </Col>
            </Row>
            </Container>


              
             <Footer/>


        </div>
    );

}
    }
export default Nutrition;