import React, { Component }  from 'react';
import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutDiv from '../components/section/about_div';
import AboutUs from '../components/section/about_us';
import Owlslider from '../components/section/owlslider';
import SubHeader from '../components/layout/subheader';
import config from '../constant';
import AboutDurgesh from '../components/section/about_durgesh';
 import MediaContent from '../components/section/media_content';


class Media extends Component {
    componentDidMount(){
        document.title = "Media | "+ config.SITE_NAME;
      }
        render(){
    return(
        <div>
            <Header/>
            <SubHeader  Title='Media' subtitle='' BkImg={config.ImageUrl+'Client-Feedback-Banner.jpg'} /> 

             <MediaContent/>
            <Footer/>


        </div>
    );

}
    }
export default Media;